// @file get current user info
import { bootCommandBarWithUser } from '@@/bits/command_bar'
import { DOMContentLoadedPromise } from '@@/bits/dom'
import window, { ww } from '@@/bits/global'
import type { User } from '@@/types'

// The User might not be set on `window` if the scripts have not run.
export const getCurrentUser = (): User =>
  window.$currentUser ??
  ww?.xstore?.state.user ??
  ww?.vueStartingState?.user ??
  ww?.vueStartingState?.currentUser ?? { id: null }

// Initialize with a potentially unsafe value, then update when the DOM is ready.
let currentUser = getCurrentUser()

bootCommandBarWithUser(currentUser)

export const currentUserPromise = DOMContentLoadedPromise.then(() => {
  const user = getCurrentUser()
  bootCommandBarWithUser(user)
  return user
})
void currentUserPromise.then((user) => (currentUser = user))

export default currentUser
